.dashboard-container {
  padding-top: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  font-family: "Roboto";
}

.top-container {
  display: grid;
  grid-template-columns: 0.7fr 3fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  font-family: "Roboto";
}

.dashboard__item {
  background: #1e1f24;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  transition: all 0.5ms ease-in-out;
}

.car__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
  background: #1e1e22;
  margin-bottom: 10px;
  min-width: 200px;
}

.car__info:last-child {
  margin-bottom: 0px;
}

.car__info-value {
  font-size: 25px;
  color: white;
}

.car__info-text {
  font-size: 25px;
  color: var(--color-orange);
  font-weight: 700;
}

.speedometers__info {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.speedometers {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.gear-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.gear-text {
  font-size: 25px;
  color: gray;
}

.gear {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  font-size: 100px;
  color: white;
}

.speedometers-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.indicators {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px;
}

.indicator {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: gray;
  border-radius: 10px;
  padding: 10px;
  font-weight: 700;
  font-size: 25px;
  background: #1e1e22;
  transition: all 0.4s ease-in-out;
}

.indicator-active {
  color: var(--color-orange);
}
.tyres-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.tyres-container-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.tyres {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.psi {
  height: 90%;
  width: 100%;
  display: flex;
}

.tyres-percentage {
  display: flex;
  align-items: center;
  height: 20%;
}

.percentage-value {
  font-size: 20px;
  color: var(--color-orange);
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  background: #1e1e22;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 25px;
  width: 85%;
  border-radius: 2px;
  display: flex;
}

.progress-bar-value {
  background: #fb3c3c;
  /* background: #469626; */
  transition: all 0.3s ease-in-out;
  height: 100%;
}

.tyres-psi {
  width: 60%;
  height: 100%;
}

.tyres-psi-container {
  height: 75%;
  align-items: center;
  display: flex;
  border-radius: 2px;
  border-radius: 5px;
}

.tyres-temp {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--color-orange);
}

.tyres-psi-1 {
  height: 100%;
  width: 33%;
  opacity: 80%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tyres-psi-2 {
  height: 100%;
  opacity: 80%;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34%;
}

.tyres-psi-3 {
  height: 100%;
  width: 33%;
  opacity: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.glow {
  box-shadow: 0 0px 20px rgba(251, 60, 60, 0.8);
  border: 1px solid rgba(251, 60, 60, 0.5) !important;
  background: rgba(251, 60, 60, 0.3) !important;
  color: white !important;
}

.transition-color {
  transition: background-color 1s ease;
  color: white;
  opacity: 60%;
}

.psi-value {
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  font-weight: 700;
}

.tyres-temp-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tyres-brake-icon {
  margin-top: 20px;
  transform: perspective(22px) translateZ(90);
}

.tyres-temp-value {
  font-size: 20px;
  color: var(--color-orange);
}

.tyre-name {
  font-size: 20px;
  color: white;
}

.bottom-container {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
}

.progress-bars {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.progress-bar-container:last-child {
  margin-left: 20px;
}

.progress-bar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.progress-bar-inner-value {
  font-size: 20px;
  color: var(--color-orange);
  width: 100%;
  text-align: center;
}

.chart-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.chart {
  height: 100%;
  width: 100%;
}

.chart-line {
  width: 100%;
}

.bar-title {
  color: gray;
  font-size: 20px;
}

.damage-container {
  display: flex;
  height: 100%;
}

.car_damage {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 10px;
}

.car-damage-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: #1e1e22;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 30px;
  transition: all 0.4s ease-in-out;
  min-width: 500px;
  color: var(--color-orange);
}

.damage {
  justify-content: center;
}

.car-damage-info-value {
  width: 5px;
  color: white;
}

.car {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.car-image {
  position: relative;
}

.car-skeleton {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.car-front {
  width: 100%;
  height: 10%;
  opacity: 60%;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

.car-side {
  width: 100%;
  height: 40%;
  opacity: 60%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.car-inner-1 {
  height: 100%;
  width: 100%;
}

.car-wheels {
  width: 100%;
  height: 20%;
  opacity: 60%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.car-back {
  width: 100%;
  height: 10%;
  opacity: 60%;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.custom-tooltip {
  background: #1e1e22;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.4s ease-in-out;
  color: var(--color-orange);
}

.progress-bar-chart {
  transform: rotate(180deg);
  height: 80%;
  width: 25px;
  display: block;
}
