.leaderboard__container {
  display: grid;
}

.leaderboard {
  width: 100%;
  font-family: "Roboto";
}

.leaderboard__header {
  display: grid;
  grid-template-columns: 0.5fr repeat(3, 1.5fr) repeat(8, 0.7fr);
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-orange); 
  text-transform: uppercase;
  pointer-events: none;
}

.leaderboard__header-minified {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-orange);
  text-transform: uppercase;
  pointer-events: none;
  height: 40px;
  position: relative;
}

.leaderboard__driver {
  display: grid;
  grid-template-columns: 0.5fr repeat(3, 1.5fr) repeat(8, 0.7fr);
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(226, 226, 226);
  background: #1e1f24;
  border-radius: 8px;
  margin-bottom: 10px;
  height: 50px;
  align-items: center;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.leaderboard__driver-minified {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr;
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
  color: rgb(226, 226, 226);
  background: #1e1f24;
  border-radius: 8px;
  margin-bottom: 10px;
  height: 50px;
  align-items: center;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.leaderboard__driver-minified:hover {
  border: 1px solid #fb923c90;
  z-index: 50;
}

.leaderboard__driver--selected {
  border: 1px solid var(--color-orange);
  z-index: 50;
}

.leaderboard__driver--selected:hover {
  border: 1px solid var(--color-orange);
  z-index: 50;
}

.leaderboard__nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 24px;
  font-weight: 600;
  color: rgb(226, 226, 226);
  background: #1e1f24;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 500px;
}

.header__left {
  text-align: left;
}

.header__center {
  text-align: center;
}

.header__orange {
  color: var(--color-orange);
}

.header__place {
  position: relative;
  font-size: 20px;
  font-family: "Racing Sans One";
}

.header__place-current {
  position: absolute;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.selected__driver-info {
  background: #1e1f24;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.leaderboard_header_settings {
  position: absolute;
  align-items: flex-end;
  height: 100%;
  display: flex;
  padding-bottom: 10px;  
  cursor: pointer;
}

.leaderboard_header_settings:hover {
  cursor: pointer;
  opacity: 80%;
  background: red;
}

.badge {
  padding: 4px 8px;
  text-align: center;
  border-radius: 20px;
  font-weight: bold;
}

.carclass-0 div.header__center.header__orange.header__class p span { color: black; background-color: var(--color-carclass-0); }
.carclass-1 div.header__center.header__orange.header__class p span { color: black; background-color: var(--color-carclass-1); }
.carclass-2 div.header__center.header__orange.header__class p span { color: black; background-color: var(--color-carclass-2); }
.carclass-3 div.header__center.header__orange.header__class p span { color: black; background-color: var(--color-carclass-3); }
.carclass-4 div.header__center.header__orange.header__class p span { color: black; background-color: var(--color-carclass-4); }
