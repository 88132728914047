.header {
  margin-top: 50px;
  padding: 20px 10px 0px 10px;
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 2fr 1fr 1.7fr;
  grid-column-gap: 20px;
  width: 100%;
  pointer-events: none;
  font-family: "Roboto";
}

.header-divider {
  margin: 0px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-hidden {
  display: none;
}

.header__box {
  background: #1e1f24;
  border-radius: 8px;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  height: 150px;
}
.header__box-laps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header__box-timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header__box-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header__box-weather {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timer {
  font-size: 100px;
  font-weight: 700;
  color: white;
}

.sectors {
  display: flex;
  align-items: center;
  height: 75px;
}

.sector {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-right: 10px;
  margin-top: 10px;
  color: gray;
  font-weight: 700;
  font-size: 20px;
  background: #303030;
  transition: all 0.4s ease-in-out;
  font-family: "Racing Sans One", cursive;
}

.sector-active {
  width: 75px;
  height: 75px;
  font-size: 25px;
  color: white;
  background: var(--color-orange);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.laps {
  display: flex;
  padding: 0px 10px;
}

.lap {
  font-size: 75px;
  font-weight: 700;
  color: var(--color-orange);
  margin-right: 10px;
}

.lap__count {
  font-size: 75px;
  font-weight: 700;
  color: white;
  width: 90px;
}

.title {
  font-size: 25px;
  color: gray;
}

.position {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.current__position {
  font-size: 75px;
  font-weight: 700;
  color: white;
  margin-right: 10px;
}

.position__divider {
  font-size: 75px;
  font-weight: 700;
  color: var(--color-orange);
  margin-right: 10px;
}

.total__positions {
  font-size: 75px;
  font-weight: 700;
  color: var(--color-orange);
}

.position__title {
  font-size: 25px;
  color: gray;
}

.weather {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 75px;
  grid-row-gap: 20px;
  font-size: 19px;
}

.weather__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weather__text {
  color: var(--color-orange);
  margin-right: 7px;
  font-weight: 500;
}

.weather__value {
  color: white;
}

.weather__icon {
  display: flex;
  align-items: center;
}

.weather__icon > img {
  margin-right: 7px;
}
