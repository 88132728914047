.track-map {
  height: calc(100vh - 70px);
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.leaderboard-mini {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.text-anim {
  transition: all 1000ms ease-in;
  translate: z(0);
}

.track-map-status {
  position: absolute;
  visibility: hidden;
  color: white;
  left: 45%;
  text-align: center;
  line-height: 150%;
}

.dot-selected {
  color: black !important;
  font-weight: bold;
  background-color: white !important;
  z-index: 9998 !important;
}

.dot-idx-player {
  color: black !important;
  background-color: white !important;
  font-weight: bold !important;
  z-index: 9999 !important;
}

.classdot-sc {border-color: var(--color-safetycar) !important; }

.classdot-0 { border-color: var(--color-carclass-0) !important; }
.classdot-1 { border-color: var(--color-carclass-1) !important; }
.classdot-2 { border-color: var(--color-carclass-2) !important; }
.classdot-3 { border-color: var(--color-carclass-3) !important; }
.classdot-4 { border-color: var(--color-carclass-4) !important; }

.dot-idx1 { z-index: 999; }
.dot-idx2 { z-index: 998; }
.dot-idx3 { z-index: 997; }
.dot-idx4 { z-index: 996; }
.dot-idx5 { z-index: 995; }
.dot-idx6 { z-index: 994; }
.dot-idx7 { z-index: 993; }
.dot-idx8 { z-index: 992; }
.dot-idx9 { z-index: 991; }
.dot-idx10 { z-index: 990; }
.dot-idx11 { z-index: 989; }
.dot-idx12 { z-index: 988; }
.dot-idx13 { z-index: 987; }
.dot-idx14 { z-index: 986; }
.dot-idx15 { z-index: 985; }
.dot-idx16 { z-index: 984; }
.dot-idx17 { z-index: 983; }
.dot-idx18 { z-index: 982; }
.dot-idx19 { z-index: 981; }
.dot-idx20 { z-index: 980; }
.dot-idx21 { z-index: 979; }
.dot-idx22 { z-index: 978; }
.dot-idx23 { z-index: 977; }
.dot-idx24 { z-index: 976; }
.dot-idx25 { z-index: 975; }
.dot-idx26 { z-index: 974; }
.dot-idx27 { z-index: 973; }
.dot-idx28 { z-index: 972; }
.dot-idx29 { z-index: 971; }
.dot-idx30 { z-index: 970; }
.dot-idx31 { z-index: 969; }
.dot-idx32 { z-index: 968; }
.dot-idx33 { z-index: 967; }
.dot-idx34 { z-index: 966; }
.dot-idx35 { z-index: 965; }
.dot-idx36 { z-index: 964; }
.dot-idx37 { z-index: 963; }
.dot-idx38 { z-index: 962; }
.dot-idx39 { z-index: 961; }
.dot-idx40 { z-index: 960; }
.dot-idx41 { z-index: 959; }
.dot-idx42 { z-index: 958; }
.dot-idx43 { z-index: 957; }
.dot-idx44 { z-index: 956; }
.dot-idx45 { z-index: 955; }
.dot-idx46 { z-index: 954; }
.dot-idx47 { z-index: 953; }
.dot-idx48 { z-index: 952; }
.dot-idx49 { z-index: 951; }
.dot-idx50 { z-index: 950; }
.dot-idx51 { z-index: 949; }
.dot-idx52 { z-index: 948; }
.dot-idx53 { z-index: 947; }
.dot-idx54 { z-index: 946; }
.dot-idx55 { z-index: 945; }
.dot-idx56 { z-index: 944; }
.dot-idx57 { z-index: 943; }
.dot-idx58 { z-index: 942; }
.dot-idx59 { z-index: 941; }
.dot-idx60 { z-index: 940; }