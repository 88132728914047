
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

:root {
  --color-orange: #fb923c;
  --color-bg: #161616;
  --transition: all 400ms ease;
  --color-safetycar: #ffff00;
  --color-carclass-0: #fb923c;
  --color-carclass-1: #a4303f;
  --color-carclass-2: #2274a5;
  --color-carclass-3: #1d7874;
  --color-carclass-4: #7692ff;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background: #14151a;
  font-family: "Roboto";
}
