.backdrop {
  position: absolute;
  top: 50px;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  margin: 0;
}

.welcome__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px;
  border-radius: 10px;
  background: #14151a;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.welcome__container-inner {
  display: flex;
  width: fit-content;
}

.id-input {
  border: 3px solid #fb923c;
  box-sizing: border-box;
  padding: 12px 20px;
  font-family: "Roboto";
  font-size: 40px;
  border-radius: 10px;
  width: calc(1 / 4 * 100vw);
  transition: all 0.3s ease-in-out;
  height: 80px;
}

.id-input:focus {
  border: 3px solid #f97316;
}

.button__container {
  margin-left: 30px;
}

.ip__button {
  height: 80px;
  font-family: "Racing Sans One";
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 40px;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  background: #f97316;
}

.ip__button:hover {
  background: #fb923c;
}

.ip__label {
  position: absolute;
  top: -60%;
  font-size: 30px;
  color: #fb923c;
  font-weight: 800;
}

.ip__address-container {
  position: relative;
}

.loading__container-inner {
  position: absolute;
  bottom: -50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fb923c;
}