.notfound {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text-404 {
  color: #fb923c;
  font-size: 400px;
  font-family: "Racing Sans One";
}

.text-notfound {
  color: #ccc;
  font-size: 50px;
  font-family: "Racing Sans One";
}

.button-404 {
  padding: 10px 20px;
  font-size: 50px;
  font-family: "Racing Sans One";
  border: 3px solid rgba(255, 255, 255, 0.1);
  background: #f97316;
  color: white;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button-404:hover {
  background: #fb923c;
}
