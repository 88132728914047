.driver {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid rgba(253, 186, 116, 0.5);
  color: #fb923c;
  font-family: "Roboto" sans-serif;
}
.driver--selected {
  background-color: #1d1d1d;
}
.driver:last-child {
  border: none;
}

.driver:hover {
  overflow: hidden;
  background-color: #1d1d1d;
}

.text-left {
  padding: 10px;
  text-align: left;
}

.text-center {
  padding: 10px;
  text-align: center;
  color: white;
}

.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 700;
}
