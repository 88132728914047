.speedometer {
  width: 400px;
  height: 380px;
  position: relative;
}

.speedometer__inter {
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 99%;
  border-top: 2px solid #fb923c41;
  border-left: 2px solid #fb923c41;
  border-right: 2px solid #fb923c41;
  border-bottom: 2px solid transparent;
  top: 6%;
  transform: rotate(-1deg);
}

.mitter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.speed-percentage {
  display: block;
  font-size: 80px;
  color: #fff;
}

.speed__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.speed-kmh {
  display: flex;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
.counts {
  color: #fff;
  font-size: 16px;
  margin-top: 2px;
}

.speedometer-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Roboto";
}
