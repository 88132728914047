.layout {
  height: 100vh;
  overflow: scroll;
  position: relative;
}
.children {
  padding: 10px;
  height: auto;
}

.transition_item {
  background: #1e1f24;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  transition: all 0.5ms ease-in-out;
}
