.signin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.signin h1 {
  color: white;
  margin-bottom: 10px;
}

.signin__container {
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #1e1f24;
}

.social-button {
  background: transparent;
  padding: 20px 30px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 10px;
  background: #18191d;
  width: 100%;
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.social-button img {
  height: 30px;
  margin-right: 15px;
}

.demo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.button-container {
  width: 100%;
}

.social-button:hover {
  background-color: #1e1f24;
}
