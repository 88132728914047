.navigation {
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  font-family: "Racing Sans One";
  padding: 10px;
  position: fixed;
  background: #14151a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navigation__hidden {
  display: none;
}

.navigation-left {
  width: 10%;
  align-items: center;
  display: flex;
  color: gray;
  font-family: "Roboto";
  font-weight: 700;
}

.logo {
  width: 130px;
  height: 100%;
  margin-right: 10px;
}

.navigation-center {
  display: flex;
  width: 80%;
  justify-content: center;
  flex-grow: 1;
  font-size: 22px;
  line-height: 28px;
}

.navigation-right {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.navlink {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-right: 30px;
  text-decoration: none;
  color: white;
  transition: var(--transition);
}

.navlink:hover {
  color: #f97316;
}

.navlink-active {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-right: 30px;
  text-decoration: none;
  color: rgba(249, 115, 22, 1);
}

.navlink-disabled {
  pointer-events: none;
  color: rgba(128, 128, 128, 1)
}

.account-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 99%;
  border: 1px solid rgba(38, 38, 38, 1);
  transition: var(--transition);
}

.account-avatar:hover {
  background: rgba(38, 38, 38, 1);
}

.account-avatar-active {
  background: rgba(38, 38, 38, 1);
}

.avatar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  height: 25px;
  width: 25px;
  color: rgba(249, 115, 22, 1);
}

.icon {
  height: 25px;
  width: 25px;
  color: rgba(249, 115, 22, 1);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99%;
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: var(--transition);
}

.icon-container:hover {
  background-color: rgba(38, 38, 38, 1);
}

.navigation-right {
  display: flex;
}

.feedback {
  position: absolute;
  color: white;
  width: 300px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  background: rgba(38, 38, 38, 1);
  font-family: "Racing Sans One";
}

.feedback-header {
  font-size: 26px;
  display: flex;
  justify-content: center;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  background: #f97316;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}

.close-icon {
  height: 25px;
  width: 25px;
  color: white;
  cursor: pointer;
}

.feedback-body {
  padding: 10px;
}

.rating-header {
  font-size: 20px;
  margin-bottom: 10px;
}

.message-body {
  width: 100%;
  height: 150px;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  resize: none;
  border-radius: 10px;
  background: #1c1c1f;
  color: white;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: "Racing Sans One";
  font-size: 16px;
}

.feedback-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-top: 10px;
}

.feedback-button {
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  transition: all 0.2s ease-in-out;
  background: #f97316;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  font-family: "Racing Sans One";
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}

.feedback-button:hover {
  background: #fb923c;
}

.error-message {
  color: #fb3c3c;
  font-size: 16px;
  font-family: "Racing Sans One";
  height: 16px;
}